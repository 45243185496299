<template>
  <div class="footer_box">
    <div class="footer_content">
      <div class="footer_right">
        <router-link to="/about">{{ $t("message.footer.aboutTitle") }}</router-link>
        <router-link to="/policy">{{ $t("message.footer.privacyTitle") }}</router-link>
      </div>
      <div class="footer_left">
        <img src="../assets/images/email-icon.png" class="email-icon" alt="">
        <div class="email">{{ $t("message.about.email") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportWebVitals } from "@/utils/ga.js";
export default {
  name: "Footer",
  data() {
    return {
      curIndex: 1,
      search: "",
    };
  },
  methods: {
    handleGaclick(type) {
      reportWebVitals(`${type}_click_nav`, "", "nav");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.footer_box {
  background: #22262e;
  height: 100px;
  width: 100%;
  .footer_content {
    height: 100px;
    width: 100%;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    .footer_left {
      display: flex;
      align-items: center;
      .email {
        // font-weight: bolder;
        font-size: 20px;
        color: #ffffff;
        margin-left: 10px;
      }
      .email-icon {
        width: 24px;
        height: auto;
      }
    }
    .footer_right {
      // margin-left: 20px;
      display: flex;
      a {
        color: #fff;
        width: auto;
        flex-shrink: 0;
        margin-right: 30px;
      }
    }
  }
}

@media (min-width: 760px) {
  .footer_box {
    .footer_content {
      width: 1200px;
      margin: 0 auto;
      padding-left: 130px;
      padding-right: 130px;
      font-size: 18px;
    }
  }
}
</style>
